import React from 'react';
import { Link, navigate } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import useForm, { DEFAULT_FIELD, DEFAULT_BOOLEAN_FIELD } from '../../hooks/useForm';
import FullName, { validator as fullNameValidator } from '../Form/inputs/FullName';
import Email, { validator as emailValidator } from '../Form/inputs/Email';
import Password, { validator as passwordValidator } from '../Form/inputs/Password';
import checkboxFor from '../Form/inputs/Checkbox';
import ButtonWithLoading from '../Form/inputs/ButtonWithLoading';

const translations = {
  ui: {
    checkboxes: {
      isAdmin: {
        label: 'Admin',
      },
    },
    buttons: {
      emailSignUp: {
        label: 'Sign Up'
      },
    },
    links: {
      signUp: {
        engage: 'Don\'t have an account?',
        label: 'Sign Up',
      },
    }
  }
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  formFooter: {
    margin: theme.spacing(2),
  },
  formButtons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  isAdmin: {
    marginTop: theme.spacing(3),
  },
  fields: {
    margin: theme.spacing(1),
    width: 300,
  },
  link: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  engage: {
    padding: theme.spacing(1),
  },
}));

const validators = {
  fullName: fullNameValidator,
  email: emailValidator,
  password: passwordValidator,
}

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

const submitSignUpWithEmail = ({ event, inputs, props, setInputs }) => {
  const roles = {};

  if (inputs.fields.isAdmin.value) {
    roles[ROLES.ADMIN] = ROLES.ADMIN;
  }

  const {email, password, fullName} = inputs.fields;

  return props.firebase
    .doCreateUserWithEmailAndPassword(email.value, password.value)
    .then(authUser => {
      // Create a user in your Firebase realtime database
      return props.firebase.user(authUser.user.uid).set({
        fullName: fullName.value,
        email: email.value,
        roles,
      });
    })
    .then(() => {
      return props.firebase.doSendEmailVerification();
    })
    .then(() => {
      navigate(ROUTES.HOME);
      return {};
    })
    .catch(error => {
      if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
        return {
          errors: [
            { message: ERROR_MSG_ACCOUNT_EXISTS },
          ]
        }
      }
    });

};


const SignUpFormBase = props => {
  const classes = useStyles();

  const {
    inputs,
    handleInputChange,
    handleCheckboxChange,
    handleSubmit,
    handleFocus,
    handleBlur,
    loading,
    success 
  } = useForm(props, submitSignUpWithEmail, { fullName: DEFAULT_FIELD, email: DEFAULT_FIELD, password: DEFAULT_FIELD, isAdmin: DEFAULT_BOOLEAN_FIELD }, validators);

  const Checkbox = checkboxFor('isAdmin');

  return (
    <form onSubmit={event => handleSubmit(event, inputs)} className={classes.container}>
      <FullName 
        handleInputChange={handleInputChange}
        handleBlur={handleBlur}
        handleFocus={handleFocus}
        inputs={inputs}
        className={classes.fields}
      />
      <Email 
        handleInputChange={handleInputChange}
        handleBlur={handleBlur}
        handleFocus={handleFocus}
        inputs={inputs}
        className={classes.fields}
      />
      <Password
        handleInputChange={handleInputChange}
        handleBlur={handleBlur}
        handleFocus={handleFocus}
        inputs={inputs} 
        className={classes.fields}
      />
      <FormGroup classes={{root: classes.isAdmin}}>
        <Checkbox
          label={translations.ui.checkboxes.isAdmin.label}
          handleCheckboxChange={handleCheckboxChange}
          handleBlur={handleBlur}
          handleFocus={handleFocus}
          inputs={inputs}
        />
      </FormGroup>
      <Box className={classes.formButtons}>
        <ButtonWithLoading loading={loading} success={success} type="submit" label={translations.ui.buttons.emailSignUp.label} />
      </Box>
    </form>
  );
}


const SignUpLink = () => {
  const classes = useStyles();
  return (
    <Box className={classes.link}>
      <Typography component="p" className={classes.engage}>
        {translations.ui.links.signUp.engage}
        </Typography>
        <Typography component="p" className={classes.engage}>
        <Link to={ROUTES.SIGN_UP}>{translations.ui.links.signUp.label}</Link>
      </Typography>
    </Box>
  )
};

export default withFirebase(SignUpFormBase);

export { SignUpLink };
