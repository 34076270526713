import React from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import validate from 'validate.js';
import PersonRounded from '@material-ui/icons/PersonRounded';

const FIELD = 'fullName';

const translations = {
  placeHolder: 'Full Name',
  validationError: 'Your full name does not looks valid',
}

export const validator = (value, inputs) => {
  const result = validate(
    {
      fullName: value
    }, 
    { 
      fullName: {
        presence: true,
        format: {
          pattern: '.*',
          message: translations.validationError,
        }
      },
    },
  )
  const errorMessage = (result && result.fullName) ? result.fullName.join(' ') : null;
  return { valid: (result === undefined), error: errorMessage }
};

const FullName = ({ handleInputChange, handleFocus, handleBlur, inputs, ...props }) => (
  <TextField
    id={FIELD}
    placeholder={translations.placeHolder}
    onChange={handleInputChange(FIELD)}
    onBlur={handleBlur(FIELD)}
    onFocus={handleFocus(FIELD)}
    value={inputs.fields[FIELD].value}
    error={!inputs.fields[FIELD].valid}
    helperText={inputs.fields[FIELD].error}
    required={inputs.fields[FIELD].required}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton>
            <PersonRounded />
          </IconButton>
        </InputAdornment>
      ),
    }}
    {...props}
  />
);

export default FullName;
